<template>
  <div id="widget-wrapper" ref="widgetWrapper">
    <widget-header />
    <div class="content-wrapper">
      <benefit-categories />
      <router-view v-on:scrolltop="handleScrollTop" />
    </div>
  </div>

</template>

<style>
#widget-wrapper {
  all: initial; /* do not inherit any rules from outside of this widget */
  color: #2c3e50;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  padding: 0;
}

/* Remove Chrome's black outline on input focus.
  This isn't good for accessibility, but the customer wanted it this way.
  https://support.google.com/chrome/thread/49176677?hl=en
*/
*:focus{ outline: none; }

.content-wrapper {
  padding: 16px 16px 0 16px;
}
.widget-body {
  margin: 0;
}

.dir-left {
  float: left;
}

.dir-right {
  float: right;
}

</style>

<script>
import BenefitCategories from './category/BenefitCategories.vue';
import WidgetHeader from './header/WidgetHeader.vue';
import RouterView from '../router/RouterView.vue';

export default {
  name: 'layout',
  components: {
    BenefitCategories,
    WidgetHeader,
    RouterView,
  },
  methods: {
    handleScrollTop() {
      this.$refs.widgetWrapper.scrollIntoView();
    },
  },
};
</script>
