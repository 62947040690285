import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './translations/en';

Vue.use(VueI18n);

declare global {
  interface Window {
    messages: any;
  }
}

const messages = {
  en,
};

export default new VueI18n({
  messages,
  fallbackLocale: 'en', // if missing translation use en
  silentTranslationWarn: true,
});
