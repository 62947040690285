// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/warning-icon.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".filters-wrapper{display:flex;margin-bottom:12px}input{border:1px solid var(--secondary-color);padding:8px;height:38px;width:250px;box-sizing:border-box}marker{color:var(--secondary-color);font-weight:700}.v-autocomplete-list{background-color:#fff;font-weight:500;margin:0;position:absolute;z-index:10000001;width:20%;min-width:300px;border:1px solid var(--secondary-color);padding:0}.v-autocomplete-list-item{cursor:pointer;background-color:#fff;padding:8px;color:#738583}.v-autocomplete-item-active{background-color:#ddd;color:var(--primary-color)}label{height:38px}.location-icon{position:relative;top:-28px;left:8px;width:18px;height:18px}.search-icon{position:relative;right:32px;top:4px}@media (max-width:395px){.search-icon{left:170px;right:auto;top:-28px}}@media (max-width:351px){.search-icon{left:120px}}@media (max-width:300px){.search-icon{display:none}}input::-moz-placeholder{color:var(--secondary-color)}input:-ms-input-placeholder{color:var(--secondary-color)}input::placeholder{color:var(--secondary-color)}.text-filter{background-color:#fff;padding-right:32px}.country-filter{background-color:#fff;padding-left:32px;-webkit-appearance:textfield}.validation-message{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat scroll left 7.6px center;background-color:#fff;border:2px solid #ff6700;color:#ff6700;height:34px;width:206px;padding:0 8.4px 0 32.4px;display:flex;margin-right:16px;align-items:center}.filter-connect{padding:12px 0 8px 0;margin-right:16px;color:#fff;font-weight:500}@media (max-width:900px){input{width:200px}.filters-wrapper{flex-direction:column}.filter-connect{display:none}.text-filter-label{margin-bottom:8px}.validation-message{width:155.2px}}@media (max-width:350px){input{width:150px;margin-right:0}.validation-message{width:105.2px;margin-right:0}}", ""]);
// Exports
module.exports = exports;
