import { ConfigStateType, ConfigValuesType } from '@/types';
import BM2Client from '@/http/BM2Client';
import GeoClient from '@/http/GeoClient';
import VerificationClient from '@/http/VerificationClient';
import i18nProvider from '@/lang/i18nProvider';

export default {
  state: () => ({
    bearerToken: undefined,
    locale: undefined,
    align: 'dir-right',
  }),
  mutations: {
    setBearerToken(state: ConfigStateType, newKey: string) {
      state.bearerToken = newKey;
    },
    setLocale(state: ConfigStateType, locale: string) {
      state.locale = locale;
    },
    setAlign(state: ConfigStateType, align: string) {
      state.align = align;
    },
  },
  getters: {
    getConfig(state: ConfigStateType) {
      return state;
    },
  },
  actions: {
    setConfig(context: any, configValues: ConfigValuesType) {
      const bearerToken = btoa(`${configValues.applicationName}:${configValues.accessToken}`);
      context.commit('setBearerToken', bearerToken);
      context.commit('setLocale', configValues.locale);
      BM2Client.getInstance().setBearerToken(bearerToken);
      GeoClient.getInstance().setBearerToken(bearerToken);
      VerificationClient.getInstance().setBearerToken(bearerToken);
      i18nProvider.locale = configValues.locale ? configValues.locale : 'en';
      if (window.messages) {
        i18nProvider.mergeLocaleMessage(i18nProvider.locale, window.messages);
      }
      const html = document.getElementsByTagName('html');
      if (html.length && html[0].getAttribute('dir') !== null) {
        context.commit('setAlign', html[0].getAttribute('dir') === 'rtl' ? 'dir-left' : 'dir-right');
      }
    },
  },
};
