<template>
  <svg :class="iconClass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" :fill="color" width="18px" height="18px"><path d="M0 0h24v24H0z" fill="none"/><path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/></svg>
</template>

<script>
export default {
  name: 'confirm-icon',
  props: {
    iconClass: {
      type: String,
    },
    color: {
      type: String,
      required: false,
      default: 'white',
    },
  },
};
</script>
