import { FilterFormType, FilterStateType } from '@/types';

export default {
  state: () => ({
    form: {
      text: '',
      location: null,
      cardTypes: {
        isic: true,
        itic: false,
        iytc: false,
      },
      online: true,
      inStore: true,
      sort: '',
      seed: Math.floor(Math.random() * 100),
    },
  }),
  mutations: {
    setFilter(state: FilterStateType, newState: FilterFormType) {
      state.form = newState;
    },
    setTextFilter(state: FilterStateType, text: string) {
      state.form.text = text;
    },
    setLocationFilter(state: FilterStateType, suggestion: any) {
      state.form.location = suggestion;
    },
  },
  getters: {
    getFilter(state: FilterStateType) {
      return state;
    },
  },
  actions: {
    changeFilter(context: any, filterForm: FilterFormType) {
      context.dispatch('filterChange');
      context.commit('setFilter', filterForm);
    },
    changeTextFilter(context: any, text: string) {
      context.commit('setTextFilter', text);
    },
    changeLocationFilter(context: any, suggestion: any) {
      context.commit('setLocationFilter', suggestion);
    },
  },
};
