import Vue from 'vue';
import Vuex from 'vuex';
import routerModule from './router.module';
import categoryModule from './category.module';
import providerModule from './provider.module';
import locationModule from './location.module';
import filterForm from './filter.module';
import configModule from './config.module';
import voucherModule from './voucher.module';
import benefitModule from './benefit.module';
import countryModule from './country.module';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    router: routerModule,
    category: categoryModule,
    provider: providerModule,
    location: locationModule,
    filter: filterForm,
    config: configModule,
    voucher: voucherModule,
    benefit: benefitModule,
    country: countryModule,
  },
});
