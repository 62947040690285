<template>
  <form ref="filterForm" @submit="onSubmit" class="filter-form">
    <advanced-benefit-filter :values="filterValues" :onReset="onFilterReset"
        :onCardTypeChange="onCardTypeChange" />
    <benefit-filter ref="benefitFilter" :values="filterValues" />
  </form>
</template>

<style scoped>
  .filter-form {
    display: flex;
    margin-bottom: 0px;
  }
</style>

<script>
import { mapActions, mapState } from 'vuex';
import { createGeoPath } from '@/http/BM2Client';
import AdvancedBenefitFilter from './AdvancedBenefitFilter.vue';
import BenefitFilter from './BenefitFilter.vue';

export default {
  name: 'filter-form',
  data() {
    return {
      filterValues: this.getFilterState(),
      oldLocation: this.getFilterState().location,
    };
  },
  computed: {
    locationFilter() {
      return this.filterValues.location;
    },
    ...mapState({
      textFilter: (state) => state.filter.form.text,
      currentRoute: (state) => state.router.route,
    }),
  },
  methods: {
    onSubmit(e) {
      e.preventDefault();
      // This is hack for autocomplete. Because the autocomplete catches Enter.
      // But doesn't prevent the default event. So when I click Enter it submits the form
      // but the new value didn't have time to save to store. And request without location
      // was executed. If I add timeout the value saves to store resolving with correct request.
      setTimeout(() => {
        this.$emit('scrolltop');
        this.changeFilter(this.createDeepCopy(this.filterValues));
        if (this.currentRoute.id === 'detail') {
          this.redirect({ id: 'list', params: {} });
        }
        this.$refs.benefitFilter.handleFilterFormSubmit();
      }, 100);
      return false;
    },
    onFilterReset() {
      this.filterValues = this.getFilterState();
    },
    getFilterState() {
      return this.createDeepCopy(this.$store.getters.getFilter.form);
    },
    createDeepCopy(object) {
      return JSON.parse(JSON.stringify(object));
    },
    onCardTypeChange() {
      this.filterValues.cardTypes = { isic: false, itic: false, iytc: false };
    },
    ...mapActions({
      changeFilter: 'changeFilter',
      redirect: 'redirectTo',
    }),
  },
  watch: {
    textFilter(newText) {
      this.filterValues.text = newText;
      this.$refs.filterForm.dispatchEvent(new Event('submit', { cancelable: true }));
    },
    locationFilter(newLocation) {
      // Check that the location is not the same. It looks like the watch works with references.
      // That's why it thinks that the value changed. Even though it didn't.
      if (this.oldLocation && newLocation) {
        if (createGeoPath(this.oldLocation) === createGeoPath(newLocation)) {
          return;
        }
      }
      this.oldLocation = newLocation;
      this.$refs.filterForm.dispatchEvent(new Event('submit', { cancelable: true }));
    },
  },
  components: {
    AdvancedBenefitFilter,
    BenefitFilter,
  },
};

</script>
