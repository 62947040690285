<template>
  <div class="checkbox-wrapper">
    <input :id="inputName" :name="inputName" type="checkbox" :checked="value"
        :class="inputName" class="cmn-toggle cmn-toggle-round" @input="updateValue" />
    <label :for="inputName"></label>
    <div class="label">{{label}}</div>
  </div>
</template>

<style scoped>
  @import '../../assets/css/cmn-toggle.css';

  .checkbox-wrapper {
    display: flex;
    align-items: center;
  }

  input {
    margin-right: 8px;
  }

  .label {
    font-size: 11.2px;
    margin: 0 16px 0 12px;
  }

  #itic.cmn-toggle-round:checked + label:before {
    background-color: #f37526;
  }

  #iytc.cmn-toggle-round:checked + label:before {
    background-color: #2c90dc;
  }

</style>

<script>
export default {
  name: 'switch-input',
  props: {
    inputName: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
    onClick: {
      type: Function,
      default: () => null,
    },
  },
  methods: {
    updateValue(e) {
      this.onClick(e);
      this.$emit('input', e.target.checked);
    },
  },
};
</script>
