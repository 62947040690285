<template>
  <div v-html="createSuggestion"></div>
</template>

<script>
import { createSuggestionString } from './BenefitFilter.vue';

export default {
  props: {
    item: { required: true },
    searchText: { required: true },
  },
  computed: {
    createSuggestion() {
      return this.markSearchText(createSuggestionString(this.item));
    },
  },
  methods: {
    markSearchText(suggestion) {
      // Hack for support #107432. We need to get the value of input
      // for some android keyboards. We can't use ref here because
      // the input is in different component. That's why I get it using JS.
      let { searchText } = this;
      if (searchText === '') {
        // Getting country filter for PROD env. Won't work for DEV.
        // (Can't be done directly by jQuery or JS because of Shadow DOM).
        const appElement = document.getElementsByTagName('DISCOUNTS-WIDGET')[0];
        const widgetWrapper = appElement.shadowRoot.getElementById('widget-wrapper');
        const countryFilter = widgetWrapper.getElementsByClassName('country-filter')[0];
        searchText = countryFilter.value;
      }
      // Using markedText instead of searchText in return so it doesn't alter the suggestion:
      // lowercase capital -> letter.
      const markedText = suggestion.substring(0, searchText.length);
      const unmarkedText = suggestion.substring(searchText.length, suggestion.length);
      return `<marker>${markedText}</marker>${unmarkedText}`;
    },
  },
};
</script>
