import BM2Client from '@/http/BM2Client';
import { BenefitEventActionDataType } from '@/types';

interface BenefitState {
  benefitLink: string | undefined;
  mobileOnlyDialogOpen: boolean;
}

export default {
  state: () => ({
    benefitLink: undefined,
    mobileOnlyDialogOpen: false,
  }),
  mutations: {
    setBenefitLink(state: BenefitState, link: string) {
      state.benefitLink = link;
    },
    setMobileOnlyDialogOpen(state: BenefitState, openMobileOnly: boolean) {
      state.mobileOnlyDialogOpen = openMobileOnly;
    },
  },
  getters: {
    getBenefitLink(state: BenefitState) {
      return state.benefitLink;
    },
  },
  actions: {
    fetchBenefitLink(context: any, data: any) {
      // eslint-disable-next-line
      BM2Client.getInstance().fetchBenefitLink(data.benefitId, data.verification).then((response) => {
        // Set null if the request fails. The component will add webUrl of benefit to link.
        context.commit('setBenefitLink', response.status === 200 ? response.data : null);
      });
    },
    createBenefitEvents(context: any, data: BenefitEventActionDataType) {
      // eslint-disable-next-line
      data.benefits.forEach((benefit: any) => {
        BM2Client.getInstance().createBenefitEvent({
          benefitId: benefit.benefitId,
          eventType: data.eventType,
          data: data.data,
        });
      });
    },
    openMobileOnlyDialog(context: any) {
      context.commit('setMobileOnlyDialogOpen', true);
    },
    closeMobileOnlyDialog(context: any) {
      context.commit('setMobileOnlyDialogOpen', false);
    },
  },
};
