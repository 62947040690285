<template>
  <div class="loading-placeholder" v-if="loading">
    <loading-symbol />
  </div>
  <div v-else>
    <benefit-categories-view :categories="rootCategories" />
  </div>
</template>

<style scoped>
  .loading-placeholder {
    height: 32px;
    margin-bottom: 8px;
  }
</style>

<script>
import { mapState, mapActions } from 'vuex';
import BenefitCategoriesView from './BenefitCategoriesView.vue';
import LoadingSymbol from '../LoadingSymbol.vue';

export default {
  name: 'benefit-categories',
  computed: {
    rootCategories() {
      return this.$store.getters.getRootCategories;
    },
    ...mapState({
      loading: (state) => state.category.list === undefined,
    }),
  },
  components: {
    BenefitCategoriesView,
    LoadingSymbol,
  },
  methods: {
    ...mapActions({
      fetchCategories: 'fetchCategories',
    }),
  },
  created() {
    this.fetchCategories();
  },
};

</script>
