<template>
  <div class="advanced-filter-wrapper" role="search" aria-label="Advanced benefit filter">
    <button type="button" v-bind:class="{open: filterOpen}"
        @click="onFilterButtonClick" class="filter-button">
      <filter-icon v-bind:class="{open: filterOpen}" :isOpen="filterOpen"
          iconClass="icon filter-icon" />
      <span class="filter-label">{{ $t("filter.labels.filterSelect") }}</span>
    </button>
    <div v-show="filterOpen" class="advanced-filter">
      <fieldset>
        <legend class="filter-legend">{{ $t("filter.legends.cards") }}</legend>
        <div class="filter-options">
          <switch-input inputName="isic" :label="$t('common.cardTypes.isic')"
            v-model="values.cardTypes.isic" :onClick="onCardTypeChange" />
          <switch-input inputName="itic" :label="$t('common.cardTypes.itic')"
            v-model="values.cardTypes.itic" :onClick="onCardTypeChange" />
          <switch-input inputName="iytc" :label="$t('common.cardTypes.iytc')"
            v-model="values.cardTypes.iytc" :onClick="onCardTypeChange" />
        </div>
      </fieldset>
      <fieldset>
        <legend class="filter-legend">{{ $t("filter.legends.discounts") }}</legend>
        <div class="filter-options">
          <switch-input inputName="online" :label="$t('filter.discounts.online')"
            v-model="values.online" />
          <switch-input inputName="instore" :label="$t('filter.discounts.inStore')"
            v-model="values.inStore" />
        </div>
      </fieldset>
      <fieldset>
        <legend class="filter-legend">{{ $t("filter.legends.sort") }}</legend>
        <select-input :options="sortOptions" v-model="values.sort" />
      </fieldset>
      <div class="filter-buttons">
        <button type="submit" class="primary-button" @click="onSubmitClick">
          <confirm-icon iconClass="icon" />
          <span>{{ $t("common.button.confirm") }}</span>
        </button>
        <button type="button" class="secondary-button" @click="onCloseClick">
          <cancel-icon iconClass="icon" />
          <span>{{ $t("common.button.cancel") }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
  @import '../../../assets/css/button-style.css';

  .advanced-filter-wrapper {
    display: flex;
    flex-direction: column;
  }

  .advanced-filter {
    max-width: 300px;
    padding: 16px 8px 0 8px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    position: absolute;
    margin-top: 52px;
    background-color: #fff;
    z-index: 3;
  }

  .filter-options {
    display: flex;
    padding: 0;
  }

  fieldset {
    border: none;
    margin-bottom: 16px;
  }

  .filter-legend {
    color: var(--primary-color);
    font-weight: 500;
    margin-bottom: 8px;
  }

  .filter-label {
    font-weight: 500;
  }

  .filter-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 32px 14px 32px;
    margin-right: 48px;
    height: 52px;
    background-color: transparent;
    color: #fff;
    border: none;
  }

  .open.filter-button {
    color: black;
    background-color: #fff;
    margin-bottom: 0;
    border-radius: 4px 4px 0 0;
  }

  .filter-icon {
    fill: white;
  }

  .open.filter-icon {
    fill: black;
  }

  .icon {
    margin: 0 6px 0 0;
  }

  .filter-buttons {
    display: flex;
    justify-content: center;
    padding: 16px 0 16px 0;
    border-top: 1px solid #f3f3f3;
  }

  @media (max-width: 900px) {
    .filter-label {
      display: none;
    }

    .filter-button {
      margin-right: 16px;
      padding: 4px 16px 14px 16px;
    }
  }

</style>

<script>
import { FilterFormType } from '@/types';
import FilterIcon from '../../icons/FilterIcon.vue';
import CancelIcon from '../../icons/CancelIcon.vue';
import ConfirmIcon from '../../icons/ConfirmIcon.vue';
import SwitchInput from '../../inputs/SwitchInput.vue';
import SelectInput from '../../inputs/SelectInput.vue';

export default {
  name: 'advanced-benefit-filter',
  data() {
    return {
      sortOptions: [
        { name: 'recommended', value: '' },
        { name: 'newest', value: 'createdOn' },
        { name: 'alphabet', value: 'name' },
      ],
      filterOpen: false,
    };
  },
  props: {
    values: {
      type: FilterFormType,
      required: true,
    },
    onReset: {
      type: Function,
      required: true,
    },
    onCardTypeChange: {
      type: Function,
      required: true,
    },
  },
  methods: {
    onFilterButtonClick() {
      this.filterOpen = !this.filterOpen;
    },
    onSubmitClick() {
      this.filterOpen = false;
    },
    onCloseClick() {
      this.onReset();
      this.filterOpen = false;
    },
  },
  components: {
    FilterIcon,
    CancelIcon,
    ConfirmIcon,
    SwitchInput,
    SelectInput,
  },
};
</script>
