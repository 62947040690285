import BM2Client from '@/http/BM2Client';
import {
  FilterFormType, LocationType, FacetType,
} from '@/types';

interface LocationState {
  list: LocationType[] | FacetType[] | undefined;
  refresh: boolean;
}

export default {
  state: () => ({
    list: undefined,
    refresh: false,
  }),
  mutations: {
    setLocations(state: LocationState, locations: LocationType[]) {
      state.list = locations;
    },
    setRefreshFlag(state: LocationState, refresh: boolean) {
      state.refresh = refresh;
    },
  },
  getters: {
    getLocations(state: LocationState) {
      return state.list;
    },
  },
  actions: {
    fetchLocations(context: any, params: FilterFormType) {
      if (context.state.refresh || context.state.list === undefined) {
        BM2Client.getInstance().fetchLocations(params).then((response) => {
          if (response.data.facets) {
            context.commit('setLocations', response.data.facets.gpsCoordinates);
          } else {
            // eslint-disable-next-line max-len
            const validLocations = response.data.items.filter((location: LocationType) => location.latitude && location.longitude);
            context.commit('setLocations', validLocations);
          }
          context.commit('setRefreshFlag', false);
        });
      }
    },
    setRefreshFlag(context: any) {
      context.commit('setRefreshFlag', true);
    },
    resetLocations(context: any) {
      context.commit('setLocations', undefined);
    },
    filterChange(context: any) {
      context.commit('setLocations', undefined);
    },
  },
};
