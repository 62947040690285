<template>
  <div class="display-switch-wrapper">
    <div class="label" v-bind:class="{ active: isActive('list')}">
      {{ $t("filter.display.list") }}
    </div>
    <router-link name="list-link" v-bind:class="{ active: isActive('list')}"
        class="display-option list-link" :to="{ id: 'list', params: {} }">
      <list-icon iconClass="icon" />
    </router-link>
    <router-link name="map-link" v-bind:class="{ active: isActive('map')}"
        class="display-option map-link" :to="{ id: 'map', params: {} }">
      <location-icon iconClass="icon" :iconColor="locationIconColor" />
    </router-link>
    <div class="label" v-bind:class="{ active: isActive('map')}">
      {{ $t("filter.display.map") }}
    </div>
  </div>
</template>

<style scoped>
.display-switch-wrapper {
  display: flex;
  flex-direction: row;
  max-width: 250px;
  align-items: center;
  padding: 0 16px 0 16px;
  margin-bottom: 14px;
}

.label {
  margin: 0 8px 0 8px;
  color: white;
  font-weight: 500;
}

.label.active {
  border-bottom: 1px solid white;
}

@media (max-width: 900px) {
  .label {
    display: none;
  }
  .display-switch-wrapper {
    align-items: flex-start;
  }
}

@media (max-width: 300px) {
  .display-switch-wrapper {
    justify-content: center;
  }
}

.display-option {
  width: 35px;
  height: 36px;
  background-color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
  fill: white;
}

.display-option.active {
  fill: var(--secondary-color);
  background-color: white;
}

.icon {
  margin: 6px;
}
</style>

<script>
import { mapState } from 'vuex';
import RouterLink from '@/router/RouterLink.vue';
import LocationIcon from '../icons/LocationIcon.vue';
import ListIcon from '../icons/ListIcon.vue';

export default {
  name: 'display-switch',
  components: {
    LocationIcon,
    ListIcon,
    RouterLink,
  },
  computed: {
    ...mapState({
      activeView: (state) => state.router.route.id,
    }),
    locationIconColor() {
      if (this.isActive('map')) {
        return this.$colorTheme.secondary;
      }
      return '#fff';
    },
  },
  methods: {
    isActive(option) {
      if (option === this.activeView) {
        return true;
      }
      if (option === 'list' && this.activeView === 'detail') {
        return true;
      }
      return false;
    },
  },
};
</script>
