import BM2Client from '@/http/BM2Client';
import { CategoryType } from '@/types';

interface CategoryState {
  list: CategoryType[] | undefined;
  active: CategoryType;
}

export default {
  state: () => ({
    active: null,
    list: undefined,
  }),
  mutations: {
    changeCategory(state: CategoryState, newCategory: CategoryType) {
      state.active = newCategory;
    },
    setCategories(state: CategoryState, categories: CategoryType[]) {
      state.list = categories;
    },
  },
  getters: {
    currentCategory(state: CategoryState) {
      return state.active;
    },
    getAllCategories(state: CategoryState) {
      return state.list;
    },
    getRootCategories(state: CategoryState) {
      if (state.list !== undefined) {
        return state.list.filter((category: any) => category.parentCategoryId === null);
      }
      return [];
    },
  },
  actions: {
    changeCategory(context: any, category: CategoryType) {
      context.dispatch('filterChange');
      context.commit('changeCategory', category);
    },
    fetchCategories(context: any) {
      if (context.state.list === undefined) {
        BM2Client.getInstance().fetchCategories().then((response) => {
          context.commit('setCategories', response.data.category);
        });
      }
    },
  },
};
