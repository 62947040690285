import axios, { AxiosInstance } from 'axios';
import {
  IssueVoucherType, VerificationType, BenefitEventType,
} from '@/types';

interface RequestParamsType {
  [key: string]: any;
}

export function createGeoPath(location: any) {
  if (location.component[0].geoLevel === 'COUNTRY') {
    return location.component[0].geoId;
  }
  const CITY_INDEX = 0;
  const REGION_INDEX = 1;
  const COUNTRY_INDEX = 2;
  return `${location.component[COUNTRY_INDEX].geoId}:${location.component[REGION_INDEX].geoId}:${location.component[CITY_INDEX].geoId}`;
}

class BM2Client {
  private client: AxiosInstance;

  private static instance: BM2Client;

  private constructor() {
    this.client = axios.create({
      baseURL: process.env.VUE_APP_BM2_URL,
      headers: {
        ContentType: 'application/json',
        Accept: 'application/json',
      },
    });
  }

  public static getInstance(): BM2Client {
    if (!BM2Client.instance) {
      BM2Client.instance = new BM2Client();
    }
    return BM2Client.instance;
  }

  fetchCategories() {
    return this.client.get('categories', { params: { limit: 500 } });
  }

  fetchProviders(params: any) {
    return this.client.get('search/providers', { params: this.createProviderSearchParams(params, params.limit ? params.limit : 24) });
  }

  createBenefitEvent(event: BenefitEventType) {
    return this.client.post('benefitEvents', event);
  }

  setBearerToken(bearerToken: string) {
    this.client.interceptors.request.use((config) => {
      // eslint-disable-next-line
      config.headers.Authorization = `Bearer ${bearerToken}`;
      return config;
    });
  }

  // Don't like this method so much, maybe it will be best to change most
  // of the params in store to match the API params, so I can add them via foreach.
  // But on the other hand it could also cause trouble so idk.
  // eslint-disable-next-line class-methods-use-this
  private createProviderSearchParams(params: any, count: number) {
    const requestParams: RequestParamsType = this.createCommonSearchParams(params, count);
    if (params.text) {
      requestParams.text = params.text;
    }
    if (!params.online || !params.inStore) {
      // eslint-disable-next-line
      requestParams.onlineOffer = params.online ? true : false;
    }
    if (params.sort) {
      requestParams.sortKey = params.sort;
    }
    if (params.distanceLocation) {
      requestParams.location = params.distanceLocation;
    }
    if (params.offset !== null) {
      requestParams.offset = params.offset;
    }
    return requestParams;
  }

  fetchProvider(id: number) {
    return this.client.get(`providers/${id.toString()}`);
  }

  fetchBenefit(id: number) {
    return this.client.get(`benefits/${id.toString()}`);
  }

  fetchProviderBenefits(providerId: number) {
    return this.client.get('search/benefits', {
      params:
      {
        providerIds: providerId,
      },
    });
  }

  fetchBenefitLink(id: number, verification: VerificationType) {
    return this.client.get(`benefits/${id}/link`, {
      headers: {
        'Isic-Verification-Id': verification.verificationId,
        'Isic-Verification-Access-Key': verification.accessKey,
      },
    });
  }

  // eslint-disable-next-line class-methods-use-this
  private createCommonSearchParams(params: any, count: number) {
    const requestParams: RequestParamsType = {};
    requestParams.limit = count;
    if (params.cardTypes) {
      Object.keys(params.cardTypes).forEach((cardType: string) => {
        if (params.cardTypes[cardType]) {
          requestParams.cardTypes = cardType;
        }
      });
    }
    if (params.category) {
      requestParams.categoryIds = params.category;
    }
    if (params.location) {
      requestParams.geoPaths = createGeoPath(params.location);
    }
    if (params.seed) {
      requestParams.seed = params.seed;
    }
    return requestParams;
  }

  fetchLocations(params: any) {
    const requestParams: RequestParamsType = this.createCommonSearchParams(params, 500);
    if (params.text) {
      requestParams.queryString = params.text;
    }
    if (params.location) {
      requestParams.geoPaths = createGeoPath(params.location);
    }
    if (params.providerIds) {
      requestParams.providerIds = params.providerIds;
    }
    if (params.boundingBox) {
      requestParams.boundingBox = params.boundingBox;
    }
    if (params.facets) {
      requestParams.facets = params.facets;
    }
    if (params.precision) {
      requestParams.facetsPrecision = params.precision;
    }
    requestParams.fetchBenefits = true;
    return this.client.get('search/locations', { params: requestParams });
  }

  issueVoucher(issueVoucher: IssueVoucherType) {
    return this.client.post(`benefits/${issueVoucher.benefitId}/vouchers/issues`, issueVoucher);
  }

  getVoucherBarcode(voucherId: number) {
    return this.client.get(`/vouchers/${voucherId}/code`,
      {
        headers: {
          Accept: 'image/*',
        },
        responseType: 'blob',
      });
  }
}

export default BM2Client;
