import BM2Client from '@/http/BM2Client';
import {
  FilterFormType, BenefitType, ProviderType, BenefitEventType,
} from '@/types';
import shuffleArray from '@/utils';

interface ProviderDetailType {
  provider: ProviderType | undefined;
  benefits: BenefitType[] | undefined;
  featuredProviders: ProviderType[] | undefined;
}

interface ProviderState {
  list: ProviderType[] | undefined;
  detail: ProviderDetailType;
}

export default {
  state: () => ({
    list: undefined,
    detail: {
      provider: undefined,
      benefits: undefined,
      featuredProviders: undefined,
    },
  }),
  mutations: {
    setProviders(state: ProviderState, providers: []) {
      state.list = providers;
    },
    addProviders(state: ProviderState, providers: []) {
      if (state.list !== undefined) {
        state.list = state.list.concat(providers);
      }
    },
    setProviderDetail(state: ProviderState, provider: ProviderType) {
      state.detail.provider = provider;
    },
    setProviderBenefits(state: ProviderState, benefits: BenefitType[]) {
      state.detail.benefits = benefits;
    },
    setProviderFeaturedProviders(state: ProviderState, providers: ProviderType[]) {
      state.detail.featuredProviders = providers;
    },
    resetProviderDetail(state: ProviderState) {
      state.detail = {
        provider: undefined,
        benefits: undefined,
        featuredProviders: undefined,
      };
    },
    resetProviderList(state: ProviderState) {
      state.list = undefined;
    },
  },
  getters: {
    getProviders(state: ProviderState) {
      return state.list;
    },
    getProviderDetail(state: ProviderState) {
      return state.detail;
    },
    // eslint-disable-next-line
    getBenefitById: (state: ProviderState) => (benefitId: number) => {
      return state.detail.benefits?.find((benefit) => benefit.benefitId === benefitId);
    },
  },
  actions: {
    fetchProviders(context: any, params: FilterFormType) {
      if (context.state.list === undefined) {
        BM2Client.getInstance().fetchProviders(params).then((response) => {
          context.commit('setProviders', response.data.providers);
        });
      }
    },
    addProviders(context: any, params: FilterFormType) {
      const requestParams = params;
      requestParams.limit = 12;
      BM2Client.getInstance().fetchProviders(requestParams).then((response) => {
        context.commit('addProviders', response.data.providers);
      });
    },
    resetProviderDetail(context: any) {
      context.commit('resetProviderDetail');
    },
    filterChange(context: any) {
      context.commit('resetProviderList');
    },
    fetchProviderFeaturedOffers(context: any, params: FilterFormType) {
      const defaultParams = {
        limit: 5,
        seed: Math.floor(Math.random() * 100),
        offset: 0,
        online: true,
        inStore: true,
      };
      const searchParams = Object.assign(defaultParams, params);
      BM2Client.getInstance().fetchProviders(searchParams).then((response) => {
        const detailProviderId = context.getters.getProviderDetail.provider.providerId;
        // eslint-disable-next-line max-len
        const featuredProviders = response.data.providers.filter((featuredProvider: ProviderType) => featuredProvider.providerId !== detailProviderId);
        shuffleArray(featuredProviders);
        // eslint-disable-next-line max-len
        const selectedProviders = featuredProviders.slice(0, featuredProviders.length < 3 ? featuredProviders.length : 3);
        context.commit('setProviderFeaturedProviders', selectedProviders);
      });
    },
    fetchProvider(context: any, providerId: number) {
      BM2Client.getInstance().fetchProvider(providerId).then((response) => {
        context.commit('setProviderDetail', response.data);
        context.dispatch('createProviderEvent');
      }).catch(() => {
        context.commit('setProviderDetail', null);
      });
      BM2Client.getInstance().fetchProviderBenefits(providerId).then((response) => {
        // Hack that allows to change attribute name on BE in the future.
        const benefits = response.data.benefit ? response.data.benefit : response.data.benefits;
        context.commit('setProviderBenefits', benefits);
        context.dispatch('createViewEvents');
      }).catch(() => {
        context.commit('setProviderBenefits', []);
        context.commit('setProviderFeaturedProviders', []);
      });
    },
    createProviderEvent(context: any) {
      const event = {
        eventType: 'VIEW',
        providerId: context.getters.getProviderDetail.provider.providerId,
      };
      // eslint-disable-next-line
      BM2Client.getInstance().createBenefitEvent(event);
    },
    createViewEvents(context: any) {
      context.dispatch(
        'createBenefitEvents',
        {
          benefits: context.getters.getProviderDetail.benefits,
          eventType: 'VIEW',
          data: { },
        },
        { root: true },
      );
    },
  },
};
