import { ThemeOptionsType } from '@/types';

declare global {
  interface Window {
    theme: ThemeOptionsType;
  }
}

const defaultTheme = {
  colors: {
    primary: '#40b8b8',
    secondary: '#346562',
  },
};

const themeProvider = {
  install(Vue: any, options: any) {
    const theme = window.theme ? window.theme : defaultTheme;
    // eslint-disable-next-line
    Vue.prototype.$colorTheme = {
      primary: theme?.colors?.primary,
      secondary: theme?.colors?.secondary,
    };
  },
};

export default themeProvider;
