<template>
  <div v-if="html" v-html="getData" />
  <div v-else>
    {{ getData }}
  </div>
</template>

<script>
export default {
  name: 'l10n',
  data() {
    return {
      locale: this.$store.state.config.locale,
    };
  },
  computed: {
    getData() {
      if (Array.isArray(this.item)) {
        const messages = [];
        this.item.forEach((element) => messages.push(this.resolveMessage(element)));
        const message = messages.join(this.delimiter);
        return message.length > this.maxLength ? message.substring(0, this.maxLength).concat('...') : message;
      }
      return this.resolveMessage(this.item);
    },
  },
  methods: {
    resolveMessage(item) {
      const l10nsKey = Object.keys(item).find((key) => key.endsWith('L10ns') || key.endsWith('L10n'));
      const l10nName = !this.l10nName ? this.name : this.l10nName;
      if (!item[l10nsKey]) {
        return item[this.name];
      }
      const l10n = item[l10nsKey]
        .find((element) => element.languageCode === this.locale);
      if (l10n && l10n[l10nName]) {
        return l10n[l10nName];
      }
      return item[this.name];
    },
  },
  props: {
    item: {
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    l10nName: {
      type: String,
    },
    html: {
      type: Boolean,
    },
    maxLength: {
      default: 250,
      type: Number,
    },
    delimiter: {
      default: ' | ',
      type: String,
    },
  },
};
</script>

<style scoped>

</style>
