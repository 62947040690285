import BM2Client from '@/http/BM2Client';
import VerificationClient from '@/http/VerificationClient';

import {
  VerificationRequestType,
  VerificationType,
  VoucherType,
  ValidationMessageType,
  BenefitType,
} from '@/types';

interface VoucherState {
  voucherWizardOpened: boolean;
  redirectWizardOpened: boolean;
  currentStep: string | undefined;
  benefitId: number | undefined;
  benefit: BenefitType | undefined;
  verification: VerificationType | undefined;
  voucher: VoucherType | undefined;
  // As data URL
  voucherBarcode: string | undefined;
  voucherErrors: Array<ValidationMessageType>;
  loading: boolean;
}

export default {
  state: () => ({
    voucherWizardOpened: false,
    redirectWizardOpened: false,
    currentStep: undefined,
    benefitId: undefined,
    benefit: undefined,
    verification: undefined,
    voucher: undefined,
    voucherBarcode: undefined,
    voucherErrors: undefined,
    loading: false,
  }),
  mutations: {
    setCurrentStep(state: VoucherState, step: string) {
      state.currentStep = step;
    },
    setBenefitId(state: VoucherState, benefitId: number) {
      state.benefitId = benefitId;
    },
    setBenefit(state: VoucherState, benefit: BenefitType) {
      state.benefit = benefit;
    },
    setVerification(state: VoucherState, verification: VerificationType) {
      state.verification = verification;
    },
    setVoucherWizardDialogOpen(state: VoucherState, opened: boolean) {
      state.voucherWizardOpened = opened;
    },
    setRedirectWizardDialogOpen(state: VoucherState, opened: boolean) {
      state.redirectWizardOpened = opened;
    },
    setVoucher(state: VoucherState, voucher: VoucherType) {
      state.voucher = voucher;
    },
    setVoucherBarcode(state: VoucherState, barcodeDataUrl: string) {
      state.voucherBarcode = barcodeDataUrl;
    },
    setVoucherErrors(state: VoucherState, voucherError: Array<ValidationMessageType>) {
      state.voucherErrors = voucherError;
    },
    setLoading(state: VoucherState, loading: boolean) {
      state.loading = loading;
    },
  },
  getters: {
    getIssueVoucherData(state: VoucherState) {
      return {
        verificationId: state.verification?.verificationId,
        verificationAccessKey: state.verification?.accessKey,
        benefitId: state.benefitId,
      };
    },
    getVoucherId(state: VoucherState) {
      return state.voucher?.voucherId;
    },
    getBenefitId(state: VoucherState) {
      return state.benefitId;
    },
    getVerification(state: VoucherState) {
      return state.verification;
    },
    getVoucherWizardDialogOpen(state: VoucherState) {
      return state.voucherWizardOpened;
    },
  },
  actions: {
    setVoucherWizardStep(context: any, currentStep: string) {
      context.commit('setCurrentStep', currentStep);
    },
    dispatchBenefitLinkFetch(context: any) {
      context.dispatch(
        'fetchBenefitLink',
        {
          benefitId: context.getters.getBenefitId,
          verification: context.getters.getVerification,
        },
        { root: true },
      );
    },
    verifyUser(context: any, request: VerificationRequestType) {
      context.commit('setLoading', true);
      // eslint-disable-next-line
      request.benefitContext = {
        benefitId: context.getters.getBenefitId,
        redemptionType: context.getters.getVoucherWizardDialogOpen ? 'ISSUE_VOUCHER' : 'REDIRECT',
      };
      VerificationClient.getInstance().createVerification(request).then((data) => {
        context.commit('setLoading', false);
        context.commit('setVerification', data);
        if (data.result === 'VALID') {
          const benefit = context.rootGetters.getBenefitById(context.getters.getBenefitId);
          // Check if the benefit supports the verified card's card type.
          // Users with SCHOLAR card are able to get vouchers as if they have ISIC card. (#128225)
          if (data.cardType.toLowerCase() === 'scholar') {
            if (!benefit.cardTypes.includes('isic')) {
              context.commit('setCurrentStep', 'cardTypeError');
              return;
            }
          } else if (!benefit.cardTypes.includes(data.cardType.toLowerCase())) {
            context.commit('setCurrentStep', 'cardTypeError');
            return;
          }
          context.dispatch('dispatchBenefitLinkFetch');
          if (request.isRedirectVoucher) {
            context.commit('setCurrentStep', 'redirect');
          } else {
            context.dispatch('issueVoucher');
          }
        } else {
          context.commit('setCurrentStep', 'verificationError');
        }
      }).catch(() => {
        context.commit('setCurrentStep', 'verificationError');
        context.commit('setLoading', false);
      });
    },
    issueVoucher(context: any) {
      const issueVoucher = context.getters.getIssueVoucherData;
      context.commit('setLoading', true);
      // XXX: Needs to be here because voucherInfo is not indexed to elastic.
      BM2Client.getInstance().fetchBenefit(context.getters.getBenefitId).then((response) => {
        context.commit('setBenefit', response.data);
      });
      BM2Client.getInstance().issueVoucher(issueVoucher).then((response) => {
        context.commit('setVoucher', response.data);
        context.commit('setCurrentStep', 'voucher');
        if (response.data.displayMode !== 'STRING') {
          context.dispatch('getVoucherBarcode').then(() => context.commit('setLoading', false));
        } else {
          context.commit('setLoading', false);
        }
      }).catch((e) => {
        context.commit('setCurrentStep', 'voucherError');
        context.commit('setVoucherErrors', e.response.data.messages);
        context.commit('setLoading', false);
      });
    },
    getVoucherBarcode(context: any) {
      BM2Client.getInstance().getVoucherBarcode(context.getters.getVoucherId).then((response) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          context.commit('setVoucherBarcode', reader.result);
        };
        reader.readAsDataURL(response.data);
      });
    },
    openVoucherDialog(context: any, benefitId: number) {
      // If the user wants to issue voucher for different benefit.
      // We need to reset voucher data so the user is not displayed the old voucher.
      if (context.getters.getBenefitId !== benefitId) {
        context.commit('setVoucher', undefined);
        context.commit('setVoucherBarcode', undefined);
      }
      context.commit('setBenefitId', benefitId);
      context.commit('setVoucherWizardDialogOpen', true);
    },
    closeVoucherDialog(context: any) {
      context.commit('setVoucherWizardDialogOpen', false);
    },
    openRedirectDialog(context: any, benefitId: number) {
      context.commit('setBenefitId', benefitId);
      context.commit('setRedirectWizardDialogOpen', true);
    },
    closeRedirectDialog(context: any) {
      context.commit('setRedirectWizardDialogOpen', false);
    },
  },
};
