class LocationHandler {
  public static getQueryParams(): URLSearchParams {
    return new URLSearchParams(window.location.search);
  }

  public static getQueryParam(name: string): string | null {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has(name)) {
      return queryParams.get(name);
    }
    return null;
  }

  public static hasQueryParam(name: string): boolean {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.has(name);
  }
}

export default LocationHandler;
