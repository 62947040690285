<template>
  <component class="router-view" :is="currentView"></component>
</template>

<style scoped>
  .router-view {
    max-width: 1280px;
    margin-right: auto;
    margin-left: auto;
  }
</style>

<script>
import Vue from 'vue';
import { mapActions } from 'vuex';
import routes from './routes';
import LocationHandler from '../utils/LocationHandler';

export default {
  name: 'router-view',
  data() {
    return {
      currentView: {},
      scrollBarListPosition: undefined,
    };
  },
  created() {
    // eslint-disable-next-line no-restricted-globals
    history.scrollRestoration = 'manual';
    this.registerRouteStateWatcher();
    this.registerHistoryStateWatcher();
    this.initView();
  },
  beforeDestroy() {
    this.unwatch();
  },
  methods: {
    getCurrentRoute() {
      return routes.find(
        (route) => route.id === this.$store.getters.currentRoute.id,
      );
    },
    initView() {
      if (LocationHandler.hasQueryParam('providerId')) {
        const query = { providerId: parseInt(LocationHandler.getQueryParam('providerId'), 10) };
        if (LocationHandler.hasQueryParam('redeemOffer')) {
          query.redeemOffer = parseInt(LocationHandler.getQueryParam('redeemOffer'), 10);
        }
        if (LocationHandler.hasQueryParam('redirectOffer')) {
          query.redirectOffer = parseInt(LocationHandler.getQueryParam('redirectOffer'), 10);
        }
        this.redirect({ id: 'detail', params: query });
      } else {
        this.redirect({ id: 'list', params: {} });
      }
      // If not here a warning is thrown about not mounting component
      this.currentView = this.getCurrentRoute().component;
    },
    registerRouteStateWatcher() {
      this.unwatch = this.$store.watch(
        (state, getters) => getters.currentRoute,
        (newValue, oldValue) => {
          if (oldValue !== null && (newValue.id !== oldValue.id
                || JSON.stringify(newValue?.params) !== JSON.stringify(oldValue?.params))) {
            // When leaving the list route we want to save the user's scrollbar position.
            // We need to save it here otherwise it would be replaced by scrolling to top (detail).
            if (oldValue.id === 'list') {
              this.scrollBarListPosition = window.scrollY;
            }
            if (newValue.id === 'detail') {
              // Don't scroll to top of detail page when opening dialog or closing it.
              if (!newValue.params.redeemOffer && !oldValue.params.redeemOffer) {
                this.$emit('scrolltop');
              }
            }
            // Get all query params (widget) from route params (except popState)
            const { popState, ...queryParams } = newValue.params;
            // Add other query params from URL.
            const urlQueryParams = new URLSearchParams(window.location.search);
            // eslint-disable-next-line
            for (const [key, value] of urlQueryParams.entries()) {
              if (!Object.keys(queryParams).includes(key)) {
                // The widget query params are managed by state. We can't add them from URL.
                const widgetParamKeys = ['providerId', 'redeemOffer', 'redirectOffer'];
                if (!widgetParamKeys.includes(key)) {
                  queryParams[key] = value;
                }
              }
            }
            const newQueryParams = new URLSearchParams(queryParams);
            // Data for provider needs to be re-fetched when going from detail to detail.
            const oldProviderId = oldValue.params?.providerId;
            if (oldProviderId && newQueryParams.has('providerId')
                && oldProviderId.toString() !== newQueryParams.get('providerId')) {
              this.resetProviderDetail();
              this.fetchProvider(newValue.params.providerId);
            }
            const url = newQueryParams.toString() === '' ? `${window.location.origin}${window.location.pathname}`
              : `${window.location.origin}${window.location.pathname}?${newQueryParams.toString()}`;
            // Don't push to history state if the route is redirected by popstate.
            if (!this.$store.getters.currentRoute.params
                || !this.$store.getters.currentRoute.params.popState) {
              // Replacing the state for list route because we didn't knew scroll position before.
              if (oldValue.id === 'list') {
                window.history.replaceState({
                  route: oldValue, scrollY: this.scrollBarListPosition,
                }, null, window.location.href);
              }
              // Replace history state when opening/closing voucher wizard dialog.
              if (newQueryParams.has('redeemOffer') || oldValue.params?.redeemOffer
                  || newQueryParams.has('redirectOffer') || oldValue.params?.redirectOffer) {
                window.history.replaceState({
                  route: this.$store.getters.currentRoute,
                }, null, url);
              } else {
                window.history.pushState({
                  route: this.$store.getters.currentRoute,
                }, null, url);
              }
            }
          }
          this.currentView = this.getCurrentRoute().component;
        },
      );
    },
    registerHistoryStateWatcher() {
      window.onpopstate = (event) => {
        if (event.state && event.state.route) {
          const routeParams = event.state.route.params
            ? { ...event.state.route.params, popState: true } : { popState: true };
          const historyRoute = Object.assign(event.state.route, { params: routeParams });
          this.redirect(historyRoute);
          if (event.state.scrollY) {
            Vue.nextTick(() => {
              window.scrollTo({ top: event.state.scrollY, left: 0, behavior: 'smooth' });
            });
          }
        }
      };
      window.onpopstate = window.onpopstate.bind(this);
    },
    ...mapActions({
      redirect: 'redirectTo',
      fetchProvider: 'fetchProvider',
      resetProviderDetail: 'resetProviderDetail',
    }),

  },
};
</script>
