import axios, { AxiosInstance } from 'axios';
import { VerificationRequestType, VerificationType } from '@/types';

class VerificationClient {
  private client: AxiosInstance;

  private static instance: VerificationClient;

  private constructor() {
    this.client = axios.create({
      baseURL: process.env.VUE_APP_VM_URL,
      headers: {
        ContentType: 'application/json',
        Accept: 'application/json',
      },
    });
  }

  public static getInstance(): VerificationClient {
    if (!VerificationClient.instance) {
      VerificationClient.instance = new VerificationClient();
    }
    return VerificationClient.instance;
  }

  setBearerToken(bearerToken: string) {
    this.client.interceptors.request.use((config) => {
      // eslint-disable-next-line
      config.headers.Authorization = `Bearer ${bearerToken}`;
      return config;
    });
  }

  async createVerification(verificationData: VerificationRequestType): Promise<VerificationType> {
    const { isRedirectVoucher, ...body } = verificationData;
    const response = await this.client.post('verifications', body);
    return response.data;
  }
}

export default VerificationClient;
