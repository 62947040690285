// Filter types

interface CardTypes {
  isic: boolean;
  itic: boolean;
  iytc: boolean;
}

export interface FilterFormType {
  text: string;
  location: any;
  cardTypes: CardTypes;
  online: boolean;
  inStore: boolean;
  sort: string;
  limit: number;
  seed: number;
}

export interface FilterStateType {
  form: FilterFormType;
}

// Router types

export interface RouterLinkRedirectType {
  id: string;
  params: RouterLinkParams;
}

interface RouterLinkParams {
  providerId: number;
}

// Entity types

export interface BenefitType {
  benefitId: number;
  benefitSummary: string;
  description: string;
  cardTypes: Array<string>;
  availableOnline: boolean;
  webUrl: string;
  voucherRequired: boolean;
  verificationRequired: boolean;
  voucherSettings: VoucherSettingsType;
  voucherInfo: string;
  limitedOffer: boolean;
  validSince: Date;
  validUntil: Date;
  countryGeoIds: Array<number>;
  mobileOnly: boolean;
}

export interface VoucherSettingsType {
  displayMode: string;
  usageLimit: number;
}

export interface VoucherType {
  voucherId: number;
  voucherCode: string;
  benefitId: number;
  issuedOn: Date;
  deliveredOn: Date;
  cardNumber: string;
  cardholderName: string;
  cardholderEmail: string;
  cardholderId: number;
  displayMode: string;
  validTo: Date;
}

export interface IssueVoucherType {
  verificationId: number;
  verificationAccessKey: string;
  benefitId: number;
}

export interface VerificationRequestType {
  isicNumber: string;
  cardholderName: string;
  verificationType: string;
  isRedirectVoucher: boolean;
  benefitContext: BenefitContextType;
}

export interface BenefitContextType {
  benefitId: number;
  redemptionType: string;
}

export interface VerificationType {
  verificationId: number;
  cardType: string;
  status: string;
  result: string;
  verificationType: string;
  accessKey: string;
  cardId: number;
  isicNumber: string;
  personName: string;
  dateOfBirth: Date;
  validUntil: Date;
  validSince: Date;
  email: string;
}

export interface CategoryType {
  categoryId: number;
  categoryL10n: [];
  coverImageUrl: string;
  icon: [];
  iconUrl: string;
  name: string;
  parentCategoryId: number;
  translationCode: string;
}

export interface ProviderType {
  providerId: number;
  name: string;
  description: string;
  logoUrl: string;
  websiteUrl: string;
  email: string;
  phone: string;
}

export interface BenefitEventType {
  benefitId?: number;
  providerId?: number;
  customerId?: string | null;
  customerIp?: string | null;
  eventType: string;
  data?: object;
}

export interface BenefitEventActionDataType {
  benefits: Array<BenefitType>;
  eventType: string;
  data: object;
}

export interface LocationType {
  locationId: number;
  name: string;
  status: string;
  latitude: number;
  longitude: number;
  address1: string;
  address2: string;
  postalCode: string;
}

export interface FacetType {
  key: string;
  count: number;
}

export interface CountryType {
  countryGeoId: number;
  name: string;
  latitude: number;
  longitude: number;
  countryCode: string;
}

// Other

export interface ConfigStateType {
  country: string;
  bearerToken: string;
  locale: string;
  align: string;
}

export interface ConfigValuesType {
  country: string;
  accessToken: string;
  applicationName: string;
  locale: string;
}

export interface ValidationMessageType {
  source: string;
  code: string;
}

export interface ColorOptionsType {
  primary: string;
  secondary: string;
}

export interface ThemeOptionsType {
  colors: ColorOptionsType;
}
