<template>
  <!-- eslint-disable -->
  <svg :class="iconClass" fill="white" width="18px" height="18px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
  <path d="M47,12C47,10.903 46.097,10 45,10L24,10C22.903,10 22,10.903 22,12C22,13.097 22.903,14 24,14L45,14C46.097,14 47,13.097 47,12Z" :style="colorStyle"/>
  <path d="M3,14L8.35,14C9.196,16.391 11.47,18 14.007,18C17.298,18 20.007,15.292 20.007,12C20.007,8.708 17.298,6 14.007,6C11.47,6 9.196,7.609 8.35,10L3,10C1.903,10 1,10.903 1,12C1,13.097 1.903,14 3,14ZM14,10C15.097,10 16,10.903 16,12C16,13.097 15.097,14 14,14C12.903,14 12,13.097 12,12C12,10.903 12.903,10 14,10Z" :style="colorStyle"/>
  <path d="M45,22L37.65,22C36.804,19.609 34.53,18 31.993,18C28.702,18 25.993,20.708 25.993,24C25.993,27.292 28.702,30 31.993,30C34.53,30 36.804,28.391 37.65,26L45,26C46.097,26 47,25.097 47,24C47,22.903 46.097,22 45,22ZM32,26C30.903,26 30,25.097 30,24C30,22.903 30.903,22 32,22C33.097,22 34,22.903 34,24C34,25.097 33.097,26 32,26Z" :style="colorStyle"/>
  <path d="M22,22L3,22C1.903,22 1,22.903 1,24C1,25.097 1.903,26 3,26L22,26C23.097,26 24,25.097 24,24C24,22.903 23.097,22 22,22Z" :style="colorStyle" />
  <path d="M45,34L28,34C26.903,34 26,34.903 26,36C26,37.097 26.903,38 28,38L45,38C46.097,38 47,37.097 47,36C47,34.903 46.097,34 45,34Z" :style="colorStyle" />
  <path d="M18,30C15.466,30.003 13.195,31.611 12.35,34L3,34C1.903,34 1,34.903 1,36C1,37.097 1.903,38 3,38L12.35,38C13.196,40.391 15.47,42 18.007,42C21.298,42 24.007,39.291 24.007,36C24.007,32.708 21.298,30 18.007,30C18.005,30 18.002,30 18,30ZM18,38C16.903,38 16,37.097 16,36C16,34.903 16.903,34 18,34C19.097,34 20,34.903 20,36C20,37.097 19.097,38 18,38Z" :style="colorStyle" />
  </svg>
</template>

<script>
export default {
  name: 'filter-icon',
  props: {
    iconClass: {
      type: String,
    },
    isOpen: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    colorStyle() {
      if (this.isOpen) {
        return 'fill:black;fill-rule:nonzero;';
      }
      return 'fill:white;fill-rule:nonzero;';
    },
  },
};
</script>
