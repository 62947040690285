<template>
  <div class="benefit-categories" role="navigation" aria-label="Benefit categories">
    <router-link
        class="benefit-category" v-bind:class="{ active: isActive(null)}"
        :onClick="chooseCategory" :to="routerLink">
      {{ $t('common.allCategories') }}
    </router-link>
    <router-link
        class="benefit-category" v-bind:class="{ active: isActive(category.categoryId)}"
        v-bind:key="category.name" :onClick="chooseCategory" :to="routerLink"
        v-for="category in categories">
      <L10n :item="category" name="name" />
    </router-link>
  </div>
</template>

<style scoped>
  .benefit-categories {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 32px;
    margin-bottom: 16px;
  }

  @media screen and (max-width: 1100px) {
    .benefit-categories {
      flex-wrap: wrap;
      height: auto;
      align-content: space-between;
    }
  }

  .benefit-category {
    text-decoration: none;
    color: var(--secondary-color);
    font-weight: 500;
    font-size: 16px;
    margin-right: 16px;
  }
  .benefit-category:hover {
    color: var(--primary-color);
  }

  .active {
    color: var(--primary-color);
  }

</style>

<script>
import { mapActions, mapState } from 'vuex';
import RouterLink from '@/router/RouterLink.vue';
import L10n from '../L10n.vue';

export default {
  name: 'benefit-categories-view',
  props: {
    categories: {
      type: Array,
      required: true,
    },
  },
  computed: {
    routerLink() {
      if (this.$store.getters.currentRoute.id === 'map') {
        return { id: 'map', params: {} };
      }
      return { id: 'list', params: {} };
    },
    ...mapState({
      activeCategory: (state) => state.category.active,
    }),
  },
  components: {
    RouterLink,
    L10n,
  },
  methods: {
    isActive(categoryId) {
      return categoryId === this.activeCategory;
    },
    chooseCategory(event, categoryId) {
      const elementText = event.srcElement.innerText;
      const selectedCategory = this.categories.find(
        (category) => category.name === elementText || (category.categoryL10n
          && category.categoryL10n.find((categoryL10n) => categoryL10n.name === elementText)),
      );
      this.changeCategory(selectedCategory ? selectedCategory.categoryId : null);
    },
    ...mapActions({
      changeCategory: 'changeCategory',
    }),
  },
};

</script>
