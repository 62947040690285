import GeoClient from '@/http/GeoClient';
import { CountryType } from '@/types';

interface CountryState {
  list: CountryType[] | undefined;
}

export default {
  state: () => ({
    list: undefined,
  }),
  mutations: {
    setCountries(state: CountryState, countries: CountryType[]) {
      state.list = countries;
    },
  },
  getters: {
    getCountries(state: CountryState) {
      return state.list;
    },
  },
  actions: {
    fetchCountries(context: any) {
      if (context.state.list !== undefined) {
        return;
      }
      GeoClient.getInstance().fetchCountries().then((response) => {
        context.commit('setCountries', response.data.items);
      });
    },
  },
};
