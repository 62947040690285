<template>
  <a @click="navigate" :href="linkHref">
    <slot></slot>
  </a>
</template>

<script>
import { mapActions } from 'vuex';
import { RouterLinkRedirectType } from '@/types';

export default {
  name: 'router-link',
  props: {
    to: {
      type: RouterLinkRedirectType,
      required: true,
    },
    onClick: {
      type: Function,
      default: () => null,
    },
  },
  computed: {
    linkHref() {
      if (this.to.id === 'detail') {
        if (window.location.search) {
          if (window.location.search.includes('providerId=')) {
            const providerIdParam = window.location.search.match(/providerId=([^&]*)/)[0];
            return window.location.search.replace(providerIdParam, `providerId=${this.to.params.providerId}`);
          }
          return `${window.location.search}&providerId=${this.to.params.providerId}`;
        }
        return `?providerId=${this.to.params.providerId}`;
      }
      return '/';
    },
  },
  methods: {
    navigate(e) {
      e.preventDefault();
      this.onClick(e);
      this.redirect(this.to);
      return false;
    },
    ...mapActions({
      redirect: 'redirectTo',
    }),
  },
};
</script>
