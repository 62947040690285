interface RouteParam {
  key: string;
  value: any;
}

const routerModule = {
  state: () => ({
    route: {
      id: 'list',
      params: {},
    },
  }),
  mutations: {
    changeRoute(state: any, newRoute: any) {
      state.route = newRoute;
    },
  },
  getters: {
    currentRoute(state: any) {
      return state.route;
    },
  },
  actions: {
    redirectTo(context: any, route: any) {
      context.commit('changeRoute', route);
    },
    addRouteParam(context: any, routeParam: RouteParam) {
      // We need to create new route and not mutate old because of watch in RouterView.
      // (otherwise old === new in watch) See Note at https://vuejs.org/v2/api/#vm-watch
      const newRoute = JSON.parse(JSON.stringify(context.getters.currentRoute));
      newRoute.params[routeParam.key] = routeParam.value;
      context.commit('changeRoute', newRoute);
    },
    removeRouteParam(context: any, key: string) {
      const newRoute = JSON.parse(JSON.stringify(context.getters.currentRoute));
      delete newRoute.params[key];
      context.commit('changeRoute', newRoute);
    },
  },
};

export default routerModule;
