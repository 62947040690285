import axios, { AxiosInstance } from 'axios';

class GeoClient {
  private client: AxiosInstance;

  private static instance: GeoClient;

  private constructor() {
    this.client = axios.create({
      baseURL: process.env.VUE_APP_GEO_URL,
      headers: {
        ContentType: 'application/json',
        Accept: 'application/json',
      },
    });
  }

  public static getInstance(): GeoClient {
    if (!GeoClient.instance) {
      GeoClient.instance = new GeoClient();
    }
    return GeoClient.instance;
  }

  setBearerToken(bearerToken: string) {
    this.client.interceptors.request.use((config) => {
      // eslint-disable-next-line
      config.headers.Authorization = `Bearer ${bearerToken}`;
      return config;
    });
  }

  fetchGeoLocations(pattern: string) {
    const parameters = new URLSearchParams();
    parameters.append('patterns', pattern);
    parameters.append('patternLevels', 'COUNTRY');
    parameters.append('patternLevels', 'CITY');
    parameters.append('resultLevels', 'COUNTRY');
    parameters.append('resultLevels', 'CITY');
    parameters.append('limit', '10');
    const params = {
      params: parameters,
    };
    return this.client.get('suggestions', { params: parameters });
  }

  fetchCountries() {
    return this.client.get('countries', { params: { limit: 260 } });
  }
}

export default GeoClient;
