/* eslint-disable quotes */
export default {
  common: {
    button: {
      confirm: "Confirm",
      cancel: "Cancel",
    },
    cardTypes: {
      isic: "ISIC",
      itic: "ITIC",
      iytc: "IYTC",
    },
    allCategories: "All categories",
  },
  filter: {
    labels: {
      filterSelect: "Filter",
      searchFor: "Search for ...",
      cityOrCountry: "City or country",
      in: "in",
    },
    legends: {
      sort: "Sort",
      discounts: "Discounts",
      cards: "Cards",
    },
    discounts: {
      online: "Online discounts",
      inStore: "In-store discounts",
    },
    sort: {
      recommended: "Recommended",
      newest: "Newest",
      alphabet: "Alphabet",
    },
    display: {
      list: "List",
      map: "Map",
    },
    validation: {
      location: "Please select a location",
    },
  },
  provider: {
    detail: {
      discounts: "Discounts",
      about: "About",
      gallery: "Gallery",
      location: "Location on map",
      suggestion: "You may be also interested in these offers",
      tags: "Tags",
      contact: {
        email: "Email",
        phone: "Phone",
        website: "Website",
      },
      noData: "<b>Sorry! This discount is no longer available.</b><br /><br />"
        + "You can click the category above or use the search to access all of our current discounts.",
    },
    map: {
      legend: "Map Legend",
      disclaimer: "The boundaries and designations on this map from <a href='https://www.openstreetmap.org/copyright' target='_blank'>OpenStreetMap</a> do not imply official endorsement or acceptance by the ISIC Association.",
    },
    list: {
      noData: "<b>Sorry! We couldn't find any results for your search.</b><br /><br />"
       + "Give it another go using these suggestions:<br />"
       + "<ul>"
       + "<li>Check your spelling</li>"
       + "<li>Try searching by brand or some different keywords</li>"
       + "<li>Modify the filter or change the category</li>"
       + "</ul>"
       + "If you alter your search a bit, we'll take another look and find the right discount for you.",
    },
  },
  discount: {
    fields: {
      validFor: "Valid for:",
      countries: "Available in:",
    },
    limitedOffer: {
      title: "Limited offer",
      tag: "Limited Offers",
      validity: "Ends in <b>{validUntil} days</b>",
    },
    mobileRestrict: {
      title: "ISIC Mobile App Required",
      info: "This offer is available only through the <a href='https://www.isic.org/app/' target='_blank'>ISIC mobile app</a>. Please download the app to take advantage of this discount.",
      properties: {
        heading: "The ISIC app allows you to:",
        digitalCard: "Access your digital card directly from your phone.",
        offers: "Search thousands of ISIC offers worldwide and redeem them instantly.",
        vouchers: "Collect vouchers from limited offers and store them in your digital wallet.",
        favourites: "Create your personal list of your favourites for easy future reference.",
        world: "Discover discounts around you on the map.",
      },
    },
    buttons: {
      getDiscountOnline: "Get Discount Online",
      getVoucher: "Get Voucher",
    },
    voucher: {
      verification: {
        title: "Card Verification",
        info: "Please enter your card details to redeem the offer.",
        submit: "Verify Card",
        orderInfo: {
          text: "Don't have a valid card?",
          link: "Order it here!",
        },
        fields: {
          isicNumber: "Card Number",
          cardholderName: "Name (as stated on your card)",
        },
        result: {
          invalid: "The card verification failed.",
        },
        validation: {
          numberFormat: "The card number format is invalid.",
        },
      },
      redirect: {
        title: 'Redirect',
        info: 'You are being redirected please wait a moment.',
      },
      verificationError: {
        title: "Card Verification Failed",
        info: "The information you have provided could not been verified. Useful tips:"
          + "<ul>"
          + "<li>Check that you have typed both your name and card number exactly the same way they are stated on your card.</li>"
          + "<li>Check that your card has been activated.</li>"
          + "<li>Check that your card is not expired.</li>"
          + "</ul>",
        button: "Try again",
      },
      cardTypeError: {
        title: "Card not eligible",
        info: "We are sorry, this offer is not available for your card. Please check the discount description to see which types of cards are accepted.",
        button: "Try different card",
      },
      voucher: {
        title: "Success",
        info: "The {cardType} card was verified correctly. You can find your code below.",
        copyButton: "Copy to clipboard",
        redeemInfo: "Click on the button below to get directed to the {providerName} website to redeem your code.",
        redeemButton: "Redeem voucher",
        voucherInfo: "Voucher information",
        validTo: "Valid to",
      },
      voucherError: {
        title: "Failed",
        info: "The ISIC card was verified correctly. Unfortunately there is a problem with code issuing:",
        messages: {
          usageLimit: {
            exceeded: "You claimed all your vouchers. There is no voucher left for you.",
          },
          vouchers: {
            collection: {
              empty: "All vouchers are claimed. There is no voucher left for you.",
            },
          },
          cardType: {
            notEligible: "We are sorry, this offer is not available for your card. Please check the discount description to see which types of cards are accepted.",
          },
          voucherValidity: {
            exceeded: "The benefit expired. There are no more vouchers available.",
          },
        },
      },
    },
  },
};
