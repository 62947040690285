<template>
  <header class="widget-header" ref="widgetHeader">
    <filter-form v-on:scrolltop="handleScrollTop" />
    <display-switch />
  </header>
</template>

<style scoped>
  .widget-header {
    display: flex;
    justify-content: center;
    padding-top: 12px;
    width: 100%;
    background-color: var(--primary-color);
  }
  @media screen and (max-width: 300px) {
    .widget-header {
      flex-direction: column;
    }
  }

</style>

<script>
import FilterForm from './filters/FilterForm.vue';
import DisplaySwitch from './DisplaySwitch.vue';

export default {
  name: 'widget-header',
  components: {
    FilterForm,
    DisplaySwitch,
  },
  methods: {
    handleScrollTop() {
      this.$refs.widgetHeader.scrollIntoView();
    },
  },
};
</script>
