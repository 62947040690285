<template>
  <svg :class="iconClass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px"><path d="M0 0h24v24H0z" fill="none"/><path d="M4 14h4v-4H4v4zm0 5h4v-4H4v4zM4 9h4V5H4v4zm5 5h12v-4H9v4zm0 5h12v-4H9v4zM9 5v4h12V5H9z"/></svg>
</template>

<script>
export default {
  name: 'list-icon',
  props: {
    iconClass: {
      type: String,
    },
  },
};
</script>
