<template>
  <div class="filters-wrapper" role="search" aria-label="Basic benefit filter">
    <label class="text-filter-label">
      <input v-model="values.text" class="text-filter" type="text"
        :placeholder="$t('filter.labels.searchFor')" @input="onFilterChange" />
      <search-icon iconClass="search-icon" :iconColor="iconColor" />
    </label>
    <div class="filter-connect"> {{ $t("filter.labels.in") }} </div>
    <div v-if="validationError && this.incorrectLocationValue"
        class="validation-message">
      {{ $t('filter.validation.location') }}
    </div>
    <label>
      <v-autocomplete v-show="!validationError" :items="geoLocations" v-model="values.location"
          ref="locationAutocomplete" @change="onAutocompleteChange"
          :get-label="createInputValue" :component-item='suggestionTemplate'
          :input-attrs="{ placeholder: $t('filter.labels.cityOrCountry'), class: 'country-filter' }"
          :auto-select-one-item="false" @item-selected="onSuggestionSelect">
      </v-autocomplete>
      <location-icon iconClass="location-icon" :iconColor="iconColor" />
    </label>
  </div>
</template>

<style>
  .filters-wrapper {
    display: flex;
    margin-bottom: 12px;
  }

  input {
    border: 1px solid var(--secondary-color);
    padding: 8px;
    height: 38px;
    width: 250px;
    box-sizing: border-box;
  }

  marker {
    color: var(--secondary-color);
    font-weight: 700;
  }

  .v-autocomplete-list {
    background-color: #fff;
    font-weight: 500;
    margin: 0;
    position: absolute;
    z-index: 10000001;
    width: 20%;
    min-width: 300px;
    border: 1px solid var(--secondary-color);
    padding: 0px;
  }

  .v-autocomplete-list-item {
    cursor: pointer;
    background-color: #fff;
    padding: 8px;
    color: #738583;
  }

  .v-autocomplete-item-active {
    background-color: #dddddd;
    color: var(--primary-color);
  }

  label {
    height: 38px;
  }

  .location-icon {
    position: relative;
    top: -28px;
    left: 8px;
    width: 18px;
    height: 18px;
  }

  .search-icon {
    position: relative;
    right: 32px;
    top: 4px;
  }

  @media (max-width: 395px) {
    .search-icon {
      left: 170px;
      right: initial;
      top: -28px;
    }
  }

  @media (max-width: 351px) {
    .search-icon {
      left: 120px;
    }
  }

  @media (max-width: 300px) {
    .search-icon {
      display: none;
    }
  }

  input::placeholder {
    color: var(--secondary-color);
  }

  .text-filter {
    background-color: #fff;
    padding-right: 32px;
  }

  .country-filter {
    background-color: #fff;
    padding-left: 32px;
    -webkit-appearance: textfield; /* Fix appearance in Safari */
  }

  .validation-message {
    background: url(../../../assets/warning-icon.svg) no-repeat scroll left 7.6px center;
    background-color: #fff;
    border: 2px solid #ff6700;
    color: #ff6700;
    height: 34px;
    width: 206px;
    padding: 0 8.4px 0 32.4px;
    display: flex;
    margin-right: 16px;
    align-items: center;
  }

  .filter-connect {
    padding: 12px 0 8px 0;
    margin-right: 16px;
    color: #fff;
    font-weight: 500;
  }

  @media (max-width: 900px) {
    input {
      width: 200px;
    }
    .filters-wrapper {
      flex-direction: column;
    }
    .filter-connect {
      display: none;
    }
    .text-filter-label {
      margin-bottom: 8px;
    }
    .validation-message {
      width: 155.2px;
    }
  }

  @media (max-width: 350px) {
    input {
      width: 150px;
      margin-right: 0;
    }
    .validation-message {
      width: 105.2px;
      margin-right: 0;
    }
  }

</style>

<script>
import { mapActions } from 'vuex';
import VAutocomplete from 'v-autocomplete';
// eslint-disable-next-line import/no-extraneous-dependencies
import debounce from 'lodash/debounce';
import LocationIcon from '@/components/icons/LocationIcon.vue';
import SearchIcon from '@/components/icons/SearchIcon.vue';
import GeoClient from '@/http/GeoClient';
import { FilterFormType } from '@/types';
import AutoCompleteSuggestion from './AutoCompleteSuggestion.vue';

export function createSuggestionString(geoLocation) {
  const CITY_INDEX = 0;
  const COUNTRY_INDEX = 2;
  if (geoLocation.component[0].geoLevel === 'COUNTRY') {
    return `${geoLocation.component[0].name}`;
  }
  return `${geoLocation.component[CITY_INDEX].name}, ${geoLocation.component[COUNTRY_INDEX].name}`;
}

export default {
  name: 'benefit-filter',
  props: {
    values: {
      type: FilterFormType,
      required: true,
    },
  },
  data() {
    return {
      geoLocations: [],
      suggestionTemplate: AutoCompleteSuggestion,
      incorrectLocationValue: false,
      validationError: false,
      validationShowTimeout: undefined,
      suggestionSelected: false,
    };
  },
  computed: {
    iconColor() {
      return this.$colorTheme.secondary;
    },
  },
  methods: {
    handleFilterFormSubmit() {
      if (this.incorrectLocationValue) {
        this.validationError = true;
        this.validationShowTimeout = setTimeout(() => {
          this.validationError = false;
        }, 1000);
      }
    },
    onFilterChange: debounce(function () {
      this.changeTextFilter(this.values.text);
    }, 1000),
    // This method is to check if the user removed the text. If yes, remove suggestions.
    // Or if he selected location and then hit backspace.
    // If normal change occurs we set flag that tracks if correct suggestion was chosen.
    // In this case it is not. If onSuggestionSelect handler is called than yes.
    onAutocompleteChange(pattern) {
      let searchText = pattern;
      // Hack for support #107432. https://github.com/vuejs/vue/issues/9777
      if (pattern === '') {
        searchText = this.getAutocompleteInputValue();
      }
      // Hack for support #107432. We cannot use @update-items because the v-model
      // of the component is not updated for some android keyboards.
      if (searchText.length >= 2) {
        this.onAutocompleteUpdateSuggestions(searchText);
      }
      if (searchText === '' || this.suggestionSelected) {
        this.incorrectLocationValue = false;
        this.suggestionSelected = false;
        this.geoLocations = [];
      } else {
        this.incorrectLocationValue = true;
      }
    },
    getAutocompleteInputValue() {
      const input = this.$refs.locationAutocomplete.$el.children[0].children[0];
      return input.value;
    },
    onSuggestionSelect() {
      this.incorrectLocationValue = false;
      this.suggestionSelected = true;
    },
    onAutocompleteUpdateSuggestions: debounce(function (pattern) {
      this.fetchGeoLocations(pattern);
    }, 500),
    fetchGeoLocations(pattern = '') {
      GeoClient.getInstance().fetchGeoLocations(`${pattern}*`).then((response) => {
        this.geoLocations = response.data.suggestion;
      });
    },
    createInputValue(suggestion) {
      if (suggestion) {
        return createSuggestionString(suggestion);
      }
      return '';
    },
    ...mapActions({
      changeTextFilter: 'changeTextFilter',
    }),
  },
  components: {
    VAutocomplete,
    LocationIcon,
    SearchIcon,
  },
  beforeDestroy() {
    if (this.validationShowTimeout) {
      clearTimeout(this.validationShowTimeout);
    }
  },
};

</script>
