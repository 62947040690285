<template>
  <label>
    <select ref="select" :name="selectName" :class="selectClass" :value="value"
        @input="updateValue">
      <option v-for="option in options" :value="option.value" :key="option.value">
        {{ $t("filter.sort." + option.name) }}
      </option>
    </select>
    <select-arrow-icon iconClass="icon" />
  </label>
</template>

<style scoped>

  select {
    border: 1px solid var(--secondary-color);
    background-color: white;
    font-size: 11.2px;
    padding: 8px;
    width: 100%;
    appearance: none;
    padding-right: 24px;
  }

  .icon {
    position: absolute;
    right: 32px;
    margin-top: 8px;
  }

  /* Hide arrow icon in IE browsers */
  .select-css::-ms-expand {
      display: none;
  }

</style>

<script>
import SelectArrowIcon from '@/components/icons/SelectArrowIcon.vue';

export default {
  name: 'select-input',
  props: {
    value: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    selectName: {
      type: String,
      required: false,
    },
    selectClass: {
      type: String,
      required: false,
    },
  },
  methods: {
    updateValue(e) {
      this.$emit('input', e.target.value);
    },
  },
  components: {
    SelectArrowIcon,
  },
};
</script>
