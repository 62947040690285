<template>
  <layout v-if="widgetLoaded" :style="themeColors" />
</template>

<script>
import Vue from 'vue';
import { mapActions } from 'vuex';
import ThemeProvider from '@/theme/themeProvider';
import GeoClient from '@/http/GeoClient';
import store from './store';
import Layout from './components/Layout.vue';
import i18nProvider from './lang/i18nProvider';

const i18n = i18nProvider;

Vue.use(ThemeProvider);

export default {
  name: 'DiscountsWidget',
  store,
  i18n,
  data() {
    return {
      widgetLoaded: false,
      colorTheme: {},
    };
  },
  props: {
    accessToken: {
      type: String,
      required: true,
    },
    applicationName: {
      type: String,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    defaultLocation: {
      type: String,
      required: false,
    },
  },
  components: {
    Layout,
  },
  methods: {
    ...mapActions({
      setConfig: 'setConfig',
      changeLocationFilter: 'changeLocationFilter',
    }),
  },
  computed: {
    themeColors() {
      return {
        '--primary-color': this.$colorTheme?.primary,
        '--secondary-color': this.$colorTheme?.secondary,
      };
    },
  },
  async created() {
    this.setConfig({
      accessToken: this.accessToken,
      applicationName: this.applicationName,
      locale: this.locale,
    });
    if (this.defaultLocation) {
      const response = await GeoClient.getInstance().fetchGeoLocations(this.defaultLocation);
      if (response.data.suggestion && response.data.suggestion.length !== 0) {
        await this.changeLocationFilter(response.data.suggestion[0]);
        this.widgetLoaded = true;
      }
    }
    // Default widget load - if bad / no value is provided.
    this.widgetLoaded = true;
  },
};

</script>
