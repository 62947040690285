<template>
  <svg @click="onIconClick" :class="iconClass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" :fill="iconColor" width="18px" height="18px"><path d="M0 0h24v24H0z" fill="none"/><path d="M7 10l5 5 5-5z"/></svg>
</template>

<script>
export default {
  name: 'select-arrow-icon',
  props: {
    iconClass: {
      type: String,
    },
    onClick: {
      type: Function,
    },
  },
  computed: {
    iconColor() {
      return this.$colorTheme.secondary;
    },
  },
  methods: {
    onIconClick(e) {
      this.onClick(e);
    },
  },
};
</script>
