const routes = [
  {
    id: 'detail',
    component: () => import('../views/ProviderDetail.vue'),
  },
  {
    id: 'list',
    component: () => import('../views/ProviderList.vue'),
  },
  {
    id: 'map',
    component: () => import('../views/Map.vue'),
  },
];

export default routes;
